import './App.css';
import 'typeface-roboto';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { UserContextProvider } from './context/UserContext';
import { MainRoute } from './routes/MainRoute';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <UserContextProvider>
                <MainRoute />
            </UserContextProvider>
        </ThemeProvider>
    );
}

export default App;
