import { Grid, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';

import { AppContext } from '../context/AppContext';
import { MisionItem, HeaderSection } from '../components';
import { COLORS } from '../theme';

import { BsGem, BsEye } from 'react-icons/bs';
import { BiTargetLock } from 'react-icons/bi';

const useStyles = makeStyles((theme) => ({
    missionContainer: {
        position: 'relative',
    },
    descriptionContainer: {
        width: '100%',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        ...theme.typography.sectionTitle,
    },

    listContainer: {
        marginTop: '30px',
        marginBottom: '50px',
        '@media (max-width:820px)': {
            marginBottom: '0px',
        },
    },
    icon: {
        fontSize: '2em',
        color: 'white',
    },
}));

export const MisionList = () => {
    const classes = useStyles();
    const {
        state: { config },
    } = useContext(AppContext);

    if (config === null) return null;

    const { mision } = config;

    return (
        <Grid container className={classes.missionContainer}>
            <HeaderSection backgroundColor={COLORS.THREE} />
            {/* <div className={classes.descriptionContainer}>
                <h1 className={classes.title}>{mision.tituloSeccion}</h1>
            </div> */}
            <Grid
                container
                item
                xs={12}
                justifyContent="space-around"
                className={classes.listContainer}
            >
                <MisionItem
                    title={'Misión'}
                    description={mision.mision}
                    image={mision.urlMision}
                    icon={<BiTargetLock className={classes.icon} />}
                />
                <MisionItem
                    title={'Visión'}
                    description={mision.vision}
                    image={mision.urlVision}
                    icon={<BsEye className={classes.icon} />}
                />
                <MisionItem
                    title={'Valores'}
                    description={mision.valores}
                    image={mision.urlValores}
                    icon={<BsGem className={classes.icon} />}
                />
            </Grid>
        </Grid>
    );
};
