import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import { Controller, useForm } from 'react-hook-form';
import { LOADING, UserContext } from '../context/UserContext';
import { useMensajeUsuario } from './MensajeUsuario';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        // width: '25%',
        top: '40%',
        left: '32%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        '@media (max-width:820px)': {
            top: '40%',
            left: '8%',
        },
    },
    button: {
        border: 'none',
        backgroundColor: theme.palette.four.main,
        color: 'white',
    },
    buttonLogin: {
        marginTop: '10px',
    },
}));

const defaultValues = {
    email: 'mflorponcework@hotmail.com',
    password: '',
};

export const LoginModal = () => {
    const classes = useStyles();
    const [show, setShow] = React.useState(false);

    const { login, userState } = useContext(UserContext);
    const { open, Mensaje } = useMensajeUsuario();
    const navigate = useNavigate();
    const { control, handleSubmit, errors } = useForm({ defaultValues });

    const handleLogin = ({ email, password }) => {
        login(email, password)
            .then((r) => {
                navigate('/administracion');
            })
            .catch((error) => {
                console.log('error en form modal', error);
                open('Usuario o contraseña incorrecta');
            });
    };
    // if (userState.state === LOGGED_IN) return <Navigate to="/administracion" />;
    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const body = (
        <Grid container item direction="column" xs={10} sm={4} className={classes.paper}>
            <h3 className={classes.authTitle}>Login</h3>
            <form
                onSubmit={handleSubmit(handleLogin)}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Controller
                    control={control}
                    name="email"
                    render={({ onChange, onBlur, value }) => {
                        return (
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                placeholder="Email"
                                autoComplete={'off'}
                                style={{ marginBottom: '20px' }}
                            />
                        );
                    }}
                    rules={{
                        required: 'El campo es requerido',
                    }}
                />
                <Controller
                    control={control}
                    name="password"
                    render={({ onChange, onBlur, value }) => {
                        return (
                            <TextField
                                label="Contraseña"
                                variant="outlined"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                placeholder="Contraseña"
                                type="password"
                                autoComplete={'off'}
                            />
                        );
                    }}
                    rules={{
                        required: 'El campo es requerido',
                    }}
                />
                <Button
                    className={classes.buttonLogin}
                    disabled={userState.state === LOADING}
                    type="submit"
                >
                    {userState.state === LOADING ? <CircularProgress /> : 'Ingresar'}
                </Button>
                <Mensaje />
            </form>
        </Grid>
    );

    return (
        <div>
            <button type="button" className={classes.button} onClick={handleOpen}>
                ©
            </button>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
};
