import { Grid, makeStyles } from '@material-ui/core';

import React, { useContext } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Phone';

import { PageHeader } from '../../components/PageHeader';
import { COLORS } from '../../theme';
import { Copyright } from '../../components/Copyright';
import imageHeader from '../../theme/img/girl-standing-in-the-office-with-phone.webp';
import { ContactForm } from '../../components/ContactForm';
import { AppContext } from '../../context/AppContext';
import { Footer } from '../../components';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        '@media (max-width:820px)': {
            margin: theme.spacing(1),
        },
    },
}));

export const Contacto = () => {
    const classes = useStyles();
    const { isMobile } = useContext(AppContext);
    const {
        state: { config },
    } = useContext(AppContext);

    if (config === null) return null;
    const { whatsapp, email } = config;
    return (
        <>
            <PageHeader
                titulo={'¿En qué podemos ayudarte?'}
                sectionNameToScroll={'contactForm'}
                textoBoton={'Contactanos'}
                imageHeader={imageHeader}
                backgroundPosition={'right'}
            />
            <Grid container direction="row">
                {isMobile && (
                    <Grid item xs={12} className={classes.pageContent}>
                        <h1>Contactanos!</h1>
                        <h3>
                            Podés completar el formulario y enviarnos un mensaje con tu
                            consulta.
                        </h3>
                        <a
                            href={`https://wa.me/+54${whatsapp.numero}?text=${whatsapp.mensaje}`}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: COLORS.TWO,
                            }}
                        >
                            <Phone
                                fontSize="medium"
                                htmlColor={`${COLORS.PRIMARY}`}
                                className={classes.icon}
                                style={{ marginRight: '5px' }}
                            />
                            <h3>(+54) 1166067915 (Bs As)</h3>
                        </a>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <Phone
                                fontSize="medium"
                                htmlColor={`${COLORS.PRIMARY}`}
                                className={classes.icon}
                                style={{ marginRight: '5px' }}
                            />
                            <h3>(+54) 0261 7097907 (Mendoza)</h3>
                        </div>
                        <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: COLORS.TWO,
                            }}
                            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`}
                        >
                            <EmailIcon
                                fontSize="medium"
                                htmlColor={`${COLORS.PRIMARY}`}
                                className={classes.icon}
                                style={{ marginRight: '5px' }}
                            />
                            <h3>{email}</h3>
                        </a>
                    </Grid>
                )}
                <ContactForm />
                {!isMobile && (
                    <Grid item xs={4} className={classes.pageContent}>
                        <h1>Contactanos!</h1>
                        <h3>
                            Podés completar el formulario y enviarnos un mensaje con tu
                            consulta.
                        </h3>
                        <a
                            href={`https://wa.me/+54${whatsapp.numero}?text=${whatsapp.mensaje}`}
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: COLORS.TWO,
                            }}
                        >
                            <Phone
                                fontSize="medium"
                                htmlColor={`${COLORS.PRIMARY}`}
                                className={classes.icon}
                                style={{ marginRight: '5px' }}
                            />
                            <h3>(+54) {whatsapp.numero} (Bs As)</h3>
                        </a>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <Phone
                                fontSize="medium"
                                htmlColor={`${COLORS.PRIMARY}`}
                                className={classes.icon}
                                style={{ marginRight: '5px' }}
                            />
                            <h3>(+54) 0261 7097907 (Mendoza)</h3>
                        </div>
                        <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: COLORS.TWO,
                                width: '50%'
                            }}
                            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`}
                        >
                            <EmailIcon
                                fontSize="medium"
                                htmlColor={`${COLORS.PRIMARY}`}
                                className={classes.icon}
                                style={{ marginRight: '5px' }}
                            />
                            <h3>{email}</h3>
                        </a>
                    </Grid>
                )}
            </Grid>
            {
                isMobile ? <Footer /> : <Copyright />
            }
            
        </>
    );
};
