import React, { useContext, useState } from 'react';
import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { AppContext, types } from '../../context/AppContext';
import {
    editMisionSeccion,

} from '../../api/config';
import { useMensajeUsuario } from '../../components/MensajeUsuario';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        '@media (max-width:820px)': {
            margin: theme.spacing(1),
        },
    },
}));

const defaultValues = {
    tituloSeccion: '',
    descripcionSeccion: '',
    mision: '',
    urlMision: '',
    urlValores: '',
    urlVision: '',
    valores: '',
    vision: '',
};
export const AdminMision = () => {
    const classes = useStyles();
    const {
        state: { config },
        editSection,
    } = useContext(AppContext);
    const { open, Mensaje } = useMensajeUsuario();
    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reset({
            tituloSeccion: config?.mision ? config.mision.tituloSeccion : '',
            descripcionSeccion: config?.mision ? config.mision.descripcionSeccion : '',
            mision: config?.mision ? config.mision.mision : '',
            urlMision: config?.mision ? config.mision.urlMision : '',
            urlValores: config?.mision ? config.mision.urlValores : '',
            urlVision: config?.mision ? config.mision.urlVision : '',
            valores: config?.mision ? config.mision.valores : '',
            vision: config?.mision ? config.mision.vision : '',
        });
    }, [config?.mision, reset]);

    if (config === null) return null;

    const { team } = config;

    const handleEditSeccion = (data) => {
        setLoading(true);
        editMisionSeccion(data)
            .then(() => {
                editSection(types.MISION_SECTION_EDIT, data);
                setLoading(false);
                open('La edición se realizó de forma correcta');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                open('Ha ocurrido un error al actualizar');
            });
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Typography variant="h4">Empresa</Typography>
            </Grid>
            <Grid item>
                <Paper className={classes.pageContent}>
                    <Grid container direction="column" spacing={4}>
                        <form
                            onSubmit={handleSubmit(handleEditSeccion)}
                            style={{ margin: '15px' }}
                        >
                            <Grid container item direction="column" spacing={2}>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'tituloSeccion'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Título'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.tituloSeccion}
                                                    helperText={
                                                        errors.tituloSeccion?.message
                                                    }
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'descripcionSeccion'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Descripción'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.descripcionSeccion}
                                                    helperText={
                                                        errors.descripcionSeccion?.message
                                                    }
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'mision'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Misión'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.mision}
                                                    helperText={errors.mision?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'urlMision'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Url Misión'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.urlMision}
                                                    helperText={errors.urlMision?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'vision'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Visión'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.vision}
                                                    helperText={errors.vision?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'urlVision'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Url Visión'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.urlVision}
                                                    helperText={errors.urlVision?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'valores'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Valores'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.valores}
                                                    helperText={errors.valores?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'urlValores'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Url Valores'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.urlValores}
                                                    helperText={
                                                        errors.urlValores?.message
                                                    }
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: COLORS.THREE,
                                            color: COLORS.CONTRAST,
                                        }}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Editar
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={20}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Paper>
                <Mensaje />
            </Grid>
        </Grid>
    );
};
