import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import imageHeader from '../../theme/img/group-people-working-out-business-plan-office.webp';
import logo from '../../theme/img/logo-horizontal-color.png';
import { MisionList, Services, Team, Footer, PageHeader } from '../../components';

import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: '50%',
        '@media (max-width:820px)': {
            width: '130%',
        },
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    // image: {
    //     position: 'absolute',
    //     top: '50px',
    //     '@media (max-width:820px)': {
    //         position: 'relative',
    //         top: 0,
    //     },
    // },
    messageContainer: {
        margin: '30px',
        '@media (max-width:820px)': {
            marginBottom: '0px',
        },
    },
    textLarge: {
        fontSize: '1.5vw',
        '@media (max-width:820px)': {
            fontSize: '18px',
        },
    },
    textMedium: {
        fontSize: '1.3vw',
        '@media (max-width:820px)': {
            fontSize: '15px',
        },
    },
}));

export const Home = () => {
    const classes = useStyles();
    const {
        state: { config }
    } = useContext(AppContext);
    if (config === null) return null;
    const { header, welcome } = config;
    return (
        <>
            <PageHeader
                titulo={header.titulo}
                sectionNameToScroll={'mensajeInicial'}
                textoBoton={'Mas Información'}
                imageHeader={imageHeader}
            />
            <Grid container id="mensajeInicial">
                <Grid container item xs={12} className={classes.welcome}>
                    <Grid
                        item
                        container
                        xs={12}
                        className={classes.messageContainer}
                        direction="row"
                        spacing={3}
                        justifyContent="center"
                    >
                        <Grid item xs={12} md={6}>
                            <span className={classes.textMedium}>
                                {welcome.descripcion}
                            </span>
                            <h2 className={classes.textMedium}>
                                {welcome.tituloPorQueElegirnos}
                            </h2>
                            <span className={classes.textMedium}>
                                {welcome.contenidoPorQueElegirnos}
                            </span>
                        </Grid>
                        <Grid item xs={6} className={classes.logoContainer}>
                            <img src={logo} alt="logo" className={classes.logo}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div id="servicios">
                <Services />
            </div>
            <div id="mision">
                <MisionList />
            </div>
            <div id="team">
                <Team />
            </div>
            <Footer />
        </>
    );
};
