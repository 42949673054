import { useMediaQuery } from '@material-ui/core';
import { useMemo, useReducer } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { getConfig } from '../api/config';

export const AppContext = createContext();

export const types = {
    SET_CONFIG: 'SET_CONFIG',
    SERVICES_SECTION_EDIT: 'SERVICES_SECTION_EDIT',
    TEAM_SECTION_EDIT: 'TEAM_SECTION_EDIT',
    MISION_SECTION_EDIT: 'MISION_SECTION_EDIT',
    CONTACT_SECTION_EDIT: 'CONTACT_SECTION_EDIT',
    ITEMSERVICE_EDIT: 'ITEMSERVICE_EDIT',
    ITEMTEAM_EDIT: 'ITEMTEAM_EDIT',
};

const initialState = {
    config: null,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case types.SET_CONFIG:
            return {
                ...state,
                config: action.payload,
            };
        case types.SERVICES_SECTION_EDIT:
            return {
                ...state,
                config: {
                    ...state.config,
                    servicio: {
                        ...state.config.servicio,
                        ...action.payload,
                    },
                },
            };
        case types.TEAM_SECTION_EDIT:
            return {
                ...state,
                config: {
                    ...state.config,
                    team: {
                        ...state.config.team,
                        ...action.payload,
                    },
                },
            };
        case types.MISION_SECTION_EDIT:
            return {
                ...state,
                config: {
                    ...state.config,
                    mision: {
                        ...state.config.mision,
                        ...action.payload,
                    },
                },
            };
        case types.CONTACT_SECTION_EDIT:
            const {
                email,
                facebook,
                instagram,
                linkedin,
                mensajeWhatsapp,
                numeroWhatsapp,
            } = action.payload;
            return {
                ...state,
                config: {
                    ...state.config,
                    email: email,
                    facebook: facebook,
                    linkedin: linkedin,
                    instagram: instagram,
                    whatsapp: {
                        ...state.config.whatsapp,
                        mensaje: mensajeWhatsapp,
                        numero: numeroWhatsapp,
                    },
                },
            };

        case types.ITEMSERVICE_EDIT:
            const newStateServicios = [...state.config.servicio.servicios];
            newStateServicios[action.payload.itemIndex] = action.payload.newItem;

            return {
                ...state,
                config: {
                    ...state.config,
                    servicio: {
                        ...state.config.servicio,
                        servicios: newStateServicios,
                    },
                },
            };
        case types.ITEMTEAM_EDIT:
            const newStateMiembros = [...state.config.team.miembros];
            newStateMiembros[action.payload.itemIndex] = action.payload.newItem;
            return {
                ...state,
                config: {
                    ...state.config,
                    team: {
                        ...state.config.team,
                        miembros: newStateMiembros,
                    },
                },
            };
        default:
            return state;
    }
};

export const AppContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const isMobile = useMediaQuery('(max-width:820px)');
    const loadConfig = async () => {
        const config = await getConfig();
        dispatch({ type: types.SET_CONFIG, payload: config });
    };

    const editSection = (type, updateFields) => {
        dispatch({ type: type, payload: updateFields });
    };

    const editItem = (type, newItem, itemIndex) => {
        dispatch({ type: type, payload: { itemIndex, newItem } });
    };

    useEffect(() => {
        loadConfig();
    }, []);

    const contextValue = useMemo(
        () => ({
            state,
            isMobile,
            editSection,
            editItem,
        }),
        [state, isMobile]
    );
    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
