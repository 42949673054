import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#FFFFFF',
        fontSize: '1.5vw',
        '@media (max-width:820px)': {
            textAlign: 'center',
            fontSize: '2rem',
        },
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '30px',
    },
}));

export const HeaderSection = ({ backgroundColor, title = '' }) => {
    const classes = useStyles();

    return (
        <div
            style={{ backgroundColor: backgroundColor, height: '3vw', width: '100%' }}
            className={classes.title}
        >
            {title}
        </div>
    );
};
