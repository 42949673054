import {
    AppBar,
    CssBaseline,
    Grid,
    makeStyles,
    Toolbar,
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { DrawerComponent, SocialMedia } from '../components';
import { AppContext } from '../context/AppContext';
import { goToSection } from '../helpers/functions';
import { COLORS } from '../theme';
// import logo from '../theme/img/mayo_logo_horizontal.png';
// import logo from '../theme/img/mayo_isologo.png';
import logo from '../theme/img/headerLogoWhite.png';
import logoColor from '../theme/img/headerLogo.png';

const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: 'none',
    },
    appBarChangeColor: {
        transition: '.3s ease-in-out',
        // boxShadow: 'none',
        boxShadow: `-2px 1px 5px -2px ${COLORS.DARKGREY}`,
    },
    navlinks: {
        // marginLeft: theme.spacing(3),
        display: 'flex',
        marginRight: '100px',
    },
    logo: {
        // flexGrow: '1',
        // cursor: 'pointer',
        backgroundImage: 'url(../theme/img/mayo_logo_RGB_Fondo transparente-01.png)',
    },
    link: {
        textDecoration: 'none',
        color: COLORS.WHITE,
        fontSize: '20px',
        marginLeft: theme.spacing(3),
        cursor: 'pointer',
        '&:hover': {
            // color: theme.palette.secondary.main,
            borderBottom: `1px solid white`,
        },
    },
    linkColor: {
        textDecoration: 'none',
        color: COLORS.DARKGREY,
        fontSize: '20px',
        marginLeft: theme.spacing(3),
        cursor: 'pointer',
        '&:hover': {
            // color: theme.palette.secondary.main,
            borderBottom: `1px solid white`,
        },
    },
}));

export const Navbar = () => {
    const classes = useStyles();
    const { isMobile } = useContext(AppContext);
    const [changeColor, setChangeColor] = useState(false);
    const {
        state: { config },
    } = useContext(AppContext);
    const setFixed = () => {
        if (window.scrollY > 0) setChangeColor(true);
        else setChangeColor(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', setFixed);
        return () => {
            window.removeEventListener('scroll', setFixed);
        };
    }, []);

    if (config === null) return null;
    return (
        <>
            <AppBar
                // color={}
                className={changeColor ? classes.appBarChangeColor : classes.appBar}
                style={{ backgroundColor: changeColor ? 'white' : 'transparent' }}
            >
                <CssBaseline />
                <Toolbar>
                    {isMobile ? (
                        <DrawerComponent changeColor={changeColor}/>
                    ) : (
                        <Grid container justifyContent='space-between'
                            // style={{
                            //     display: 'flex',
                            //     alignItems: 'center',
                            //     justifyContent: 'space-between',
                            // }}
                        >
                            <Grid style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    !changeColor ? <img src={logo} alt={'logo mayo consultores'}/> : <img src={logoColor} alt={'logo mayo consultores'} />
                                }
                            
                            </Grid>
                            <Grid item style={{display:'flex', alignItems: 'center'}}>
                                <div className={classes.navlinks}>
                                    <HashLink
                                        smooth
                                        to={'/'}
                                        className={
                                            changeColor ? classes.linkColor : classes.link
                                        }
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                    >
                                        Inicio
                                    </HashLink>
                                    <HashLink
                                        to={'/#servicios'}
                                        className={
                                            changeColor ? classes.linkColor : classes.link
                                        }
                                        style={{ cursor: 'pointer' }}
                                        scroll={(el) => goToSection(el)}
                                    >
                                        {config.servicio.tituloSeccion}
                                    </HashLink>
                                    <HashLink
                                        to={'/#mision'}
                                        className={
                                            changeColor ? classes.linkColor : classes.link
                                        }
                                        style={{ cursor: 'pointer' }}
                                        scroll={(el) => goToSection(el)}
                                    >
                                        {config.mision.tituloSeccion}
                                    </HashLink>
                                    <HashLink
                                        to={'/#team'}
                                        className={
                                            changeColor ? classes.linkColor : classes.link
                                        }
                                        style={{ cursor: 'pointer' }}
                                        scroll={(el) => goToSection(el)}
                                    >
                                        {config.team.tituloSeccion}
                                    </HashLink>

                                    {/* <Link
                                        to="/faq"
                                        className={
                                            changeColor ? classes.linkColor : classes.link
                                        }
                                    >
                                        Ofertas Laborales
                                    </Link> */}
                                    <Link
                                        to="/contacto"
                                        className={
                                            changeColor ? classes.linkColor : classes.link
                                        }
                                        onClick={() =>
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                    >
                                        Contacto
                                    </Link>
                                </div>
                                <SocialMedia iconColor="darkGrey" />
                            </Grid>
                        </Grid>
                    )}
                    {/* </Grid> */}
                </Toolbar>
            </AppBar>
        </>
    );
};
