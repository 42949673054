import React, { useEffect, useState } from 'react';

export const useAdaptativeHeight = () => {
    const [height, setHeight] = useState(window.innerHeight);
    const fixImage = () => {
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', fixImage);
        return () => {
            window.removeEventListener('resize', fixImage);
        };
    }, []);

    return {
        height,
    };
};
