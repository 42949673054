import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

import { useAdaptativeHeight } from '../hooks/useAdaptativeHeight';
import { COLORS } from '../theme';
import { goToSection } from '../helpers/functions';

const useStyles = makeStyles((theme) => ({
    header: {
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    headerContent: {
        display: 'flex',
        height: '100%',
        width: '100%',
        background: 'linear-gradient(rgb(22 22 22 / 60%), rgb(0 0 0 / 10%))',
        justifyContent: 'flex-start', //
        alignItems: 'end', //
        paddingLeft: '20px',
        '@media (max-width:820px)': {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },

    headerContentNoButton: {
        display: 'flex',
        height: '100%',
        width: '100%',
        background: 'linear-gradient(rgb(22 22 22 / 60%), rgb(0 0 0 / 10%))',
        justifyContent: 'center', //
        alignItems: 'center', //
        paddingLeft: '20px',
        '@media (max-width:820px)': {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    containterTextButton: {
        margin: '40px 20px',
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width:820px)': {
            padding: '10px 10px',
            marginBottom: '40px',
        },
    },
    textCenter: {
        textAlign: 'center'
    },
    headerText: {
        color: '#FFFFFF',
        fontFamily: 'Lato',
        fontSize: '2.5vw',
        '@media (max-width:820px)': {
            textAlign: 'center',
            fontSize: '2rem',
        },
    },
    masInfo: {
        textDecoration: 'none',
        borderRadius: '30px',
        padding: '20px 40px',
        backgroundColor: `${COLORS.FOUR}`,
        color: 'white',
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        fontSize: '1.2rem',
        '@media (max-width:820px)': {
            fontSize: '1.1rem',
            padding: '15px 30px',
            margin: 'auto',
        },
        '@media (min-width: 820px) and (max-width:1281px)': {
            fontSize: '1rem',
            padding: '15px 25px',
            // margin: 'auto',
        },
        gap: '10px'
    },

    flecha: {
        fontSize: '1.5rem',
        paddingRight: '5px',
        '@media (max-width:820px)': {
            fontSize: '1.5rem',
        },
    },
}));

export const PageHeader = ({
    titulo,
    sectionNameToScroll,
    textoBoton,
    imageHeader,
    backgroundPosition = 'center center',
    customHeight = '',
    hasButton = true
}) => {
    const classes = useStyles();
    const { height } = useAdaptativeHeight();
    const { pathname } = useLocation();
    return (
        <Grid container>
            <Grid
                container
                item
                className={classes.header}
                style={{
                    height: customHeight === '' ? height : customHeight,
                    backgroundImage: `url(${imageHeader})`,
                    backgroundPosition: backgroundPosition,
                }}
            >
                <div className={hasButton ? classes.headerContent : classes.headerContentNoButton}>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        className={classes.containterTextButton}
                    >
                        <h1 className={`${classes.headerText} ${ !hasButton ? classes.textCenter : ''}`}>{titulo}</h1>
                        {
                            hasButton && (<Link
                                to={`${pathname}#${sectionNameToScroll}`}
                                className={classes.masInfo}
                                style={{ cursor: 'pointer' }}
                                scroll={(el) => goToSection(el)}
                            >
                                <BsArrowRight className={classes.flecha} />
                                <span>{textoBoton}</span>
                            </Link>)
                        }
                        
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};
