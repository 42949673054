import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AppContext } from '../context/AppContext';
import { goToSection } from '../helpers/functions';
import logoColor from '../theme/img/headerLogo.png';

const useStyles = makeStyles((theme) => ({
    appBarChangeColor: {
        transition: '.3s ease-in-out',
        boxShadow: 'none',
    },
    drawerContainer: {
        backgroundColor: theme.palette.primary.main,
        height: '100%',
    },
    navlinks: {
        // marginLeft: theme.spacing(3),
        display: 'flex',
        marginRight: '100px',
    },
    logo: {
        flexGrow: '1',
        cursor: 'pointer',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '20px',
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            borderBottom: `1px solid white`,
        },
        '@media (max-width:820px)': {
            fontSize: '16px',
        },
    },
    navbarLogo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '25px'
    },
    navbarImg: {
        width: '70px'
    },
    iconColorWhite: {
        color: '#FFFFFF'
    }
    
}));

export const DrawerComponent = ({changeColor}) => {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const {
        state: { config },
    } = useContext(AppContext);
    if (config === null) return null;
    return (
        <>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <HashLink
                                    smooth
                                    to={'/'}
                                    className={classes.link}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        window.scrollTo({ top: 0, behavior: 'smooth' })
                                    }
                                >
                                    Inicio
                                </HashLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <HashLink
                                    to={'/#servicios'}
                                    className={classes.link}
                                    style={{ cursor: 'pointer' }}
                                    scroll={(el) => goToSection(el)}
                                >
                                    {config.servicio.tituloSeccion}
                                </HashLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <HashLink
                                    to={'/#mision'}
                                    className={classes.link}
                                    style={{ cursor: 'pointer' }}
                                    scroll={(el) => goToSection(el)}
                                >
                                    {config.mision.tituloSeccion}
                                </HashLink>
                            </ListItemText>
                        </ListItem>
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <HashLink
                                    to={'/#team'}
                                    className={classes.link}
                                    style={{ cursor: 'pointer' }}
                                    scroll={(el) => goToSection(el)}
                                >
                                    {config.team.tituloSeccion}
                                </HashLink>
                            </ListItemText>
                        </ListItem>
                        {/* <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <Link className={classes.link} to="/about">
                                    Ofertas Laborales
                                </Link>
                            </ListItemText>
                        </ListItem> */}
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <Link className={classes.link} to="/contacto">
                                    Contacto
                                </Link>
                            </ListItemText>
                        </ListItem>
                    </List>
                </div>
            </Drawer>

            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon className={!changeColor ? classes.iconColorWhite : ''}/>
            </IconButton>
            {
                changeColor && (<div className={classes.navbarLogo}>
                    <img src={logoColor} alt={'logo mayo consultores'} className={classes.navbarImg}/>
                    </div>)
            }
            

        </>
    );
};
