export const goToSection = (element) => {
    // const anchor = document.querySelector(`#${sectionName}`);
    // if (anchor != null) anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    var headerOffset = 64;
    if (element !== null) {
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
};

export const getDefaultValues = (campos) => {
    const keyValue = campos.map((c) => [c, '']);
    //convierte una lista de key-value a un objeto
    const defaultValues = Object.fromEntries(keyValue);
    return defaultValues;
};
