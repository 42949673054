import { Navbar } from '../components';

// const PublicRoute = (props) => <Route {...props} />;

export const PublicRoute = ({ component: Component }) => {
    return (
        <>
            <Navbar />
            <Component />
        </>
    );
};
