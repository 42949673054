import { Grid, makeStyles } from '@material-ui/core';
import { useContext } from 'react';

import { AppContext } from '../context/AppContext';
import { COLORS } from '../theme';
import { HeaderSection } from './HeaderSection';
import { ServiceItem } from './ServiceItem';

const useStyles = makeStyles((theme) => ({
    servicesContainer: {
        // background: 'linear-gradient(0deg, #ffffff 30%, #1B3B6F 80%, #1B3B6F 10%)',
    },
    serviceInnerConainer: {
        marginTop: '20px',
    },
    bottomContainer: {
        margin: '30px',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    title: {
        ...theme.typography.sectionTitle,
        display: 'block',
        borderBottom: `4px solid ${theme.palette.two.main}`,
    },
    subtitle: {
        ...theme.typography.sectionSubtitle,
    },
}));

export const Services = () => {
    const classes = useStyles();
    const {
        state: { config },
        isMobile,
    } = useContext(AppContext);
    if (config === null) return null;
    const { servicio } = config;
    return (
        <Grid container className={classes.servicesContainer}>
            <HeaderSection backgroundColor={COLORS.WHITE} />
            {isMobile && (
                <Grid container item xs={12} className={classes.bottomContainer}>
                    <Grid item xs={12} sm={3}>
                        <span
                            className={classes.title}
                        >
                            {servicio.tituloSeccion}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={9} style={{ marginTop: '14px' }}>
                        <span className={classes.subtitle}>
                            {servicio.descripcionSeccion}
                        </span>
                    </Grid>
                </Grid>
            )}
            <Grid
                container
                item
                xs={12}
                justifyContent="space-around"
                className={classes.serviceInnerConainer}
            >
                {servicio.servicios.map((servicio) => (
                    <ServiceItem key={servicio.titulo} item={servicio} />
                ))}
                {!isMobile && (
                    <Grid container item xs={12} className={classes.bottomContainer}>
                        <Grid item xs={12} sm={2}>
                            <span className={classes.title}>
                                {servicio.tituloSeccion}
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <span className={classes.subtitle}>
                                {servicio.descripcionSeccion}
                            </span>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
