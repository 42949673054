import { createTheme } from '@material-ui/core/styles';
import { lato } from './font';

export const COLORS = {
    // PRIMARY: '#000727',
    // LIGHT: '#256D85',
    // BLUE: '#001366',
    // SECONDARY: '#3E53B5',
    PRIMARY: '#142850',
    TWO: '#238DC1', //azul de logo
    THREE: '#238DC1',
    FOUR: '#73308A', //violeta de logo
    CONTRAST: '#FFFFFF',
    DARKGREY: '#4E4E57',
    WHITE: '#FFFFFF',
};

export const theme = createTheme({
    typography: {
        fontFamily: [
            // 'Quicksand',
            'Roboto',
            'sans-serif'
        ].join(','),
        lato: 'lato',
        latoBold: 'Lato-Bold',
        latoExtraBold: 'Lato-ExtraBold',
        latoLight: 'Lato-Light',
        sectionTitle: {
            fontSize: '2.5vw',
            color: COLORS.DARKGREY,
            fontWeight: 'bold',
            '@media (max-width:820px)': {
                fontSize: '1.7rem',
            },
        },
        sectionSubtitle: {
            fontSize: '1.3vw',
            color: COLORS.DARKGREY,
            '@media (max-width:820px)': {
                fontSize: '1rem',
            },
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [lato],
            },
        },
    },
    palette: {
        primary: {
            main: COLORS.FOUR,
            contrastText: '#FFFFFF',
        },
        two: {
            main: COLORS.TWO,
        },
        background: {
            default: '#FFFFFF',
        },
        disabled: '#ada8b6',
        three: {
            main: COLORS.THREE,
        },
        four: {
            main: COLORS.FOUR,
        },
        constrast: {
            main: COLORS.CONTRAST,
        },
        darkGrey: {
            main: COLORS.DARKGREY,
        },
        white: {
            main: COLORS.WHITE,
        },
    },
});

theme.typography.h3 = {
    fontSize: '1.4rem',
    '@media (min-width:600px)': {
        fontSize: '1.7rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.6rem',
    },
};

export default theme;
