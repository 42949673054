import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

/**
 *
 * @param {templateId de emailjs} template
 * @param {funcion que se ejecuta cuando el proceso fue success} callback
 * @param {data del formulario a enviar por email} formData
 * @returns Promesa
 */

/**
 * es una funcion asyncrona que retorna una promesa "return emailjs....". Esta promesa puede ser el response con status 200 cuando entro al then
 * o el response con status 400 cuando entro al catch. Hay errores que no entrar al catch en el emailjs, por lo tanto tengo que hacer un catch cuando
 * la llame. El catch va a recibir un texto si entra a este catch y si no entra tambien, porque asi lo esta devolviendo la api
 */

export const sendEmail = async (template, callback, formData) => {
    return emailjs
        .send(process.env.REACT_APP_EMAILJS_SERVICE_ID, template, formData)
        .then((response) => {
            callback();
            return response;
        })
        .catch((e) => {
            console.log('FAILED', e);
            return e.text;
        });
};
