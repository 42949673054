import { Grid } from '@material-ui/core';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LOGGED_OUT, UserContext } from '../context/UserContext';

export const PrivateRoute = ({ children }) => {
    const { userState } = useContext(UserContext);
    const { pathname, search } = useLocation();
    localStorage.setItem('lastLocation', `${pathname}${search}`);

    return userState.state === LOGGED_OUT ? (
        <Navigate to="/" />
    ) : (
        <Grid container style={{ marginTop: '64px' }} direction="column">
            {children}
        </Grid>
    );
};
