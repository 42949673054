export const config = {
    email: 'florenciaponce@mayoconsultores.com.ar',
    facebook: 'https://www.facebook.com/ConsultoresMayo?mibextid=ZbWKwL',
    header: {
        descripcion: '',
        titulo: 'Las grandes compañías saben que su mayor capital es su gente',
    },
    instagram: 'https://www.instagram.com/mayoconsultores/',
    mision: {
        descripcionSeccion:
            'Nuestra empresa nace con el objetivo de querer aportar ayuda a que tu organización crezca y formar parte de tu crecimiento. ',
        mision: 'Nuestra misión es que tu organización crezca y evolucione sin fronteras y ser parte nos enorgullece.',
        tituloSeccion: 'Empresa',
        urlMision:
            'mision_otwtlq.webp',
        urlValores:
            'valores_qiragk.webp',
        urlVision:
            'vision_zqnsi9.webp',
        valores:
            'Los valores que nos identifican son calidad de servicios, compromiso y profesionalismo.',
        vision: 'Somos un equipo con ambiciones concretas y el objetivo es llegar a cada organización en cualquier parte del mundo.',
    },
    servicio: {
        descripcionSeccion:
            'Realizamos la selección de personal eventual y fijo. Contamos con sólidos conocimientos en distintas áreas. Nuestros clientes avalan nuestra pasión por los resultados. Somos cazadores natos de talentos humanos y para ello contamos con los mejores selectoras especializadas en cada área. Desde el primer contacto nos encargamos de gestionar tus necesidades organizacionales. ',
        servicios: [
            {
                descripcion: ' ',
                parrafo1:
                    'Realizamos publicación de avisos, hunting con selectoras especializadas, terna de candidatos, informe individualizado de cada postulante, verificación de antecedentes.',
                parrafo2: 'Abarcamos mandos medios, gerenciales y operativos',
                    // parrafo2:
                //     'Realizamos entrevistas por competencias de acuerdo a la necesidad de cada cliente. Abarcamos empresas privadas, públicas o mixtas. Nacionales e internacionales.',
                // parrafo3: 'Abarcamos mandos medios, gerenciales y operativos',
                titulo: 'Selección de Personal IT y Generalista',
                url: 'seleccion_b3umlp.webp',
            },
            {
                descripcion:
                    'Realizamos el diseño de las evaluaciones de desempaño de 360°. Para que puedas evaluar a tus empleados de cómo trabajan y que habilidades poseen. ',
                parrafo1:
                    'Diseñamos evaluación de desempeño 360 que mide el método de trabajo (comportamientos, aptitudes y actitudes)',
                // parrafo2:
                //     'A partir de esta evaluación se podrán implementar mejoras técnicas, incorporar capacitaciones, medir las fallas para poder mejorar la productividad y la calidad de trabajo en cada sector.',
                // parrafo3:
                //     'Realizamos un análisis cualitativo y cuantitativo de todo el organigrama de tu empresa.',
                titulo: 'Evaluación de Desempeño',
                url: 'Rectangle_236_dssm5k.webp',
            },
            {
                descripcion:
                    'Contamos con los mejores profesionales conformado por psicólogos de os cuales utilizan herramientas altamente confiable para conocer recursos y características de personalidad del postulante o colaborador que, confrontadas con el perfil del puesto y la cultura de la empresa, facilita la toma de decisión en procesos de selección, reorganización y capacitación.',
                parrafo1:
                    'Realizamos todo tipo de evaluaciones psicotécnicas, psicodiagnósticas y psicolaborales:',
                parrafo2:
                    'Test psicotécnico de aptitud verbal, aptitud numérica, aptitud espacial. Pruebas psicotécnicas de razonamiento abstracto.',
                parrafo3: 'Consultar por otros tipos de test',
                titulo: 'Exámenes Psicotécnicos',
                // url: 'https://res.cloudinary.com/dusuvg3hg/image/upload/v1662382999/psicotecnico_xt3env.webp',
                url: 'psicotecnico_xt3env.webp',
            },
            {
                descripcion:
                    'Nuestro equipo esta conformado por abogados y contadores, podemos aconsejarte a la hora de tener algún inconveniente. ',
                parrafo1: 'Liquidación de sueldos, cargas sociales. Confección y presentación de declaraciones juradas. Emisión de recibos de haberes y registración en libro de sueldos. Liquidaciones finales.',
                parrafo2: 'Atención de conflictos individuales y colectivos.',
                titulo: 'Asesoría Legal y Contable',
                url: 'serviceFour_jerug2.webp',
            },
        ],
        tituloSeccion: 'Servicios',
    },
    team: {
        descripcionSeccion:
            'Cada persona que integra el equipo de Mayo Consultores está capacitada para darle a nuestros clientes el mejor asesoramiento. Conformado por contadores, abogados, psicólogos, consultores IT y profesionales de recursos humanos.',
        miembros: [
            {
                nombre: 'Florencia Ponce',
                posicion: 'CEO - Selección de Personal',
                url: 'florPonce2_kpin4c.webp',
            },
            {
                nombre: 'Gabriel Salamón',
                posicion: 'Psicólogo',
                url: 'gabrielSalamon_tcks83.webp',
            },
            {
                nombre: 'Johanna Nuñez',
                posicion: 'Contadora Pública',
                url: 'johannaNuñez_ijogxo.webp',
            },
            // {
            //     nombre: 'Alejandra Layera',
            //     posicion: 'Asesora Contable',
            //     url: 'https://res.cloudinary.com/dusuvg3hg/image/upload/v1671158285/alejandraLayera_nn5rva.webp',
            // },
            {
                nombre: 'Ramiro Quinteros',
                posicion: 'Abogado',
                url: 'ramiro.webp'
            },
            {
                nombre: 'Florencia Martin',
                posicion: 'Consultora IT',
                url: 'flormartin_teyhag.webp',
            },
        ],
        tituloSeccion: 'Nuestro Equipo',
    },
    linkedin: 'https://www.linkedin.com/company/mayo-consultores/',
    welcome: {
        contenidoPorQueElegirnos:
            'Porque estamos un paso adelante del resto, y eso nos da un sello personal: Compromiso, Personalidad, Vocación y Aprendizaje Constante.',
        descripcion:
            'Somos una compañía especializada en Recursos Humanos compuesta por jóvenes profesionales expertos en la materia. Nos moviliza la inquietud por descubrir nuevos proyectos creativos, desafiantes y llenos de pasión. Nos caracteriza la fuerza, el empuje y la adaptabilidad a nuevas formas de trabajo y tecnologías que el mercado exige.',
        titulo: 'Welcome Message',
        tituloPorQueElegirnos: '¿Por qué elegirnos?',
    },
    whatsapp: {
        mensaje: 'Hola, me interesa recibir mas información sobre Mayo Consultores',
        numero: '1166067915',
    },
};
