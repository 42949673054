import {
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import React from 'react';


const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingBottom: '10px',
        borderBottom: '1px solid grey',
        cursor: 'pointer'
    },
    headerText: {
        color: '#FFFFFF',
        fontSize: '2.5vw',
        '@media (max-width:820px)': {
            textAlign: 'center',
            fontSize: '2rem',
        },
    },
}));



export const MenuServicios = ({setOpenSelection, setOpenEvaluation, setOpenExam, setOpenAdvisory}) => {
    const classes = useStyles()

    const handleShowSelection = ()=> {
        setOpenSelection(true)
        setOpenEvaluation(false)
        setOpenExam(false)
        setOpenAdvisory(false)
    }
    
    const handleShowEvaluation = ()=> {
        setOpenSelection(false)
        setOpenEvaluation(true)
        setOpenExam(false)
        setOpenAdvisory(false)
    }
    
    const handleShowExam = ()=> {
        setOpenSelection(false)
        setOpenEvaluation(false)
        setOpenExam(true)
        setOpenAdvisory(false)
    }
    
    const handleShowAdvisory = ()=> {
        setOpenSelection(false)
        setOpenEvaluation(false)
        setOpenExam(false)
        setOpenAdvisory(true)
    }

    return (
        <Grid item xs={12} md={4}>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    Servicios
                </Typography>
                <div>
                    <List>
                        <ListItem
                            className={classes.listItem}
                            onClick={handleShowSelection}
                        >
                            <ListItemText primary="Selección de Personal IT y Generalista" />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="select item" onClick={handleShowSelection}>
                                    <ArrowForward />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            className={classes.listItem}
                            onClick={handleShowEvaluation}
                        >
                            <ListItemText primary="Evaluación de Desempeño" />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="select item" onClick={handleShowEvaluation}>
                                    <ArrowForward />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            className={classes.listItem}
                            onClick={handleShowExam}
                        >
                            <ListItemText primary="Exámenes Psicotécnicos" />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="select item" onClick={handleShowExam}>
                                    <ArrowForward />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                           className={classes.listItem}
                           onClick={handleShowAdvisory}
                        >
                            <ListItemText primary="Asesoría Legal y Contable" />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="select item" onClick={handleShowAdvisory}>
                                    <ArrowForward />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
        </Grid>
    );
};
