import React, { useContext, useState } from 'react';
import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { AppContext, types } from '../../context/AppContext';
import { AccordionItem } from '../../components/AccordionItem';
import {
    editTeams,
    editTeamSeccion,
} from '../../api/config';
import { useMensajeUsuario } from '../../components/MensajeUsuario';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        '@media (max-width:820px)': {
            margin: theme.spacing(1),
        },
    },
}));

const defaultValues = {
    tituloSeccion: '',
    descripcionSeccion: '',
};
export const AdminTeam = () => {
    const classes = useStyles();
    const {
        state: { config },
        editItem,
        editSection,
    } = useContext(AppContext);
    const { open, Mensaje } = useMensajeUsuario();
    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reset({
            tituloSeccion: config?.team ? config.team.tituloSeccion : '',
            descripcionSeccion: config?.team ? config.team.descripcionSeccion : '',
        });
    }, [config?.team, reset]);

    if (config === null) return null;

    const { team } = config;

    const handleEditServices = (data, itemIndex) => {
        const miembrosToUpdate = [...team.miembros];
        miembrosToUpdate[itemIndex] = data;
        editTeams(miembrosToUpdate)
            .then(() => {
                editItem(types.ITEMTEAM_EDIT, data, itemIndex);
                open('La edición se realizó de forma correcta');
            })
            .catch((error) => {
                console.log(error);
                open('Ha ocurrido un error al actualizar');
            });
    };

    const handleEditSeccion = (data) => {
        setLoading(true);
        editTeamSeccion(data)
            .then(() => {
                editSection(types.TEAM_SECTION_EDIT, data);
                setLoading(false);
                open('La edición se realizó de forma correcta');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                open('Ha ocurrido un error al actualizar');
            });
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Typography variant="h4">Equipo</Typography>
            </Grid>
            <Grid item>
                <Paper className={classes.pageContent}>
                    <Grid container direction="column" spacing={4}>
                        <form
                            onSubmit={handleSubmit(handleEditSeccion)}
                            style={{ margin: '15px' }}
                        >
                            <Grid container item direction="column" spacing={2}>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'tituloSeccion'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Título'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.tituloSeccion}
                                                    helperText={
                                                        errors.tituloSeccion?.message
                                                    }
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'descripcionSeccion'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Descripción'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.descripcionSeccion}
                                                    helperText={
                                                        errors.descripcionSeccion?.message
                                                    }
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: COLORS.THREE,
                                            color: COLORS.CONTRAST,
                                        }}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Editar
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={20}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item>
                            <p>Listado de Miembros</p>
                            {team.miembros.map((miembro, index) => {
                                const camposMiembro = Object.keys(miembro).sort();
                                return (
                                    <AccordionItem
                                        key={miembro.nombre}
                                        tituloItem={miembro.nombre}
                                        item={miembro}
                                        campos={camposMiembro}
                                        itemIndex={index}
                                        onEdit={handleEditServices}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </Paper>
                <Mensaje />
            </Grid>
        </Grid>
    );
};
