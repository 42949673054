import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { LoginModal } from '../components';

const useStyles = makeStyles((theme) => ({
    copyrightContainer: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.four.main,
    },
    copyrightText: {
        fontSize: '14px',
        color: 'white',
        padding: '15px',
        display: 'flex',
        flexDirection: 'row',
    },
}));

export const Copyright = () => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Grid item xs={12} className={classes.copyrightContainer}>
                <span className={classes.copyrightText}>
                    Copyright <LoginModal /> {new Date().getFullYear()} | Diseño y
                    desarrollo by{' '}
                    <a
                        style={{
                            color: 'white',
                            paddingLeft: '5px',
                        }}
                        href="https://www.linkedin.com/in/florencia-martin-206527138/"
                    >
                        flormartin
                    </a>
                </span>
            </Grid>
        </Grid>
    );
};
