import { Grid, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

const useStyles = makeStyles((theme) => ({
    teamItemContainer: {
        textAlign: 'center',
        '@media (max-width:820px)': {
            marginBottom: '40px',
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '18px',
        '@media (max-width:820px)': {
            marginTop: '0px',
        },
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: 'white',
        paddingBottom: '8px',
        '@media (max-width:820px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width: 820px) and (max-width:1281px)': {
            fontSize: '1.2rem',
        },
    },
    subtitle: {
        fontSize: '1.1rem',
        fontWeight: '300',
        color: 'white',
        marginTop: '3px',
        paddingLeft: '15px',
        paddingRight: '15px',
        '@media (max-width:820px)': {
            fontSize: '1rem',
            marginTop: '0px',
        },
        '@media (min-width: 820px) and (max-width:1281px)': {
            fontSize: '1rem',
        },
    },
}));

export const TeamItem = ({ imageSrc, nombre, puesto }) => {
    const classes = useStyles();
    const picture = require(`../theme/img/${imageSrc}`);
    const {
        isMobile,
    } = useContext(AppContext);

    return (
        <Grid item className={classes.teamItemContainer} xs={12} sm={2} >
            <Grid item>
                <img src={picture} alt={puesto} width={ isMobile ?'65%' : "80%"} />
            </Grid>
            <Grid className={classes.textContainer}>
                <span className={classes.title}>{nombre}</span>
                <span className={classes.subtitle}>{puesto}</span>
            </Grid>
        </Grid>
    );
};
