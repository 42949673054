import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles
} from '@material-ui/core';

import React, { useContext, useState } from 'react';

import { Check } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { Copyright } from '../../components/Copyright';
import { MenuServicios } from '../../components/MenuServicios';
import { AppContext } from '../../context/AppContext';
import imageHeader from '../../theme/img/close-up-of-young-group-of.webp';
import { Footer, PageHeader } from '../../components';


const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        '@media (max-width:820px)': {
            margin: theme.spacing(1),
        },
    },
    headerText: {
        color: '#FFFFFF',
        fontSize: '2.5vw',
        '@media (max-width:820px)': {
            textAlign: 'center',
            fontSize: '2rem',
        },
    },
    
}));

export const Servicios = () => {
    const {hash} = useLocation()
    const {
        state: { config },
        isMobile
    } = useContext(AppContext);

    const [openSelection, setOpenSelection] = useState(hash === '#SelecciondePersonalITyGeneralista');
    const [openEvaluation, setOpenEvaluation] = useState(hash === '#EvaluaciondeDesempeno');
    const [openExam, setOpenExam] = useState(hash === '#ExamenesPsicotecnicos');
    const [openAdvisory, setOpenAdvisory] = useState(hash === '#AsesoriaLegalyContable');

    if (config === null) return null;

    return (
        <>
            <PageHeader titulo={'Servicios'}
                sectionNameToScroll={'contactForm'}
                textoBoton={'Contactanos'}
                imageHeader={imageHeader}
                backgroundPosition={'bottom'} customHeight={'300px'} hasButton={false}/>
            <Grid
                container
                direction="row"
                style={{ marginTop: '20px', paddingLeft: '20px', marginBottom: '30px' }}
                spacing={4}
            >
                <MenuServicios
                    setOpenSelection={setOpenSelection}
                    setOpenEvaluation={setOpenEvaluation}
                    setOpenExam={setOpenExam}
                    setOpenAdvisory={setOpenAdvisory}
                />
                <Grid item xs={12} md={8}>
                    {openSelection && <Selection />}
                    {openEvaluation && <Evaluation/>}
                    {openExam && <Exam />}
                    {openAdvisory && <Advisory />}
                </Grid>
            </Grid>
            {
                isMobile ? <Footer /> : <Copyright />
            }
        </>
    );
};

const Evaluation = () => {
    return (
        <Grid>
            <h2>Evaluación de Desempeño</h2>
            <p>
                Realizamos el diseño de las evaluaciones de desempeño 360°, para que
                puedas evaluar cómo trabajan tus empleados y que habilidades poseen.
            </p>
            <p>
                A partir de esta evaluación se podrán implementar mejoras técnicas,
                incorporar capacitaciones, medir las fallas para poder mejorar la
                productividad y la calidad de trabajo en cada sector.
            </p>
            <p>
                Realizamos un análisis cualitativo y cuantitativo de todo el organigrama
                de tu empresa.
            </p>
        </Grid>
    );
};

const Exam = () => {
    return (
        <Grid>
            <h2>Exámenes Psicotécnicos</h2>
            <p>
                Contamos con los mejores profesionales conformado por psicólogos que utilizan herramientas altamente confiables para conocer 
                características de personalidad del postulante. Sumado con el perfil solicitado para el puesto y la cultura de la empresa, facilita
                la toma de decisión en procesos de selección, reorganización y capacitación.
            </p>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Test psicotécnico de aptitud verbal. Prueba psicotécnica de aptitud numérica. " />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Tests psicotécnicos de aptitud espacial. Pruebas psicotécnicas de razonamiento abstracto.
"
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Test psicotécnicos de atención, concentración y retención." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Otros test" />
                </ListItem>
            </List>
        </Grid>
    );
};
const Advisory = () => {
    return (
        <Grid>
            <h2>Asesoría Contable</h2>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Liquidación de sueldos, jornales y cargas sociales." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Confección y presentación de declaraciones juradas mensuales – Boletas Sindicatos." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Emisión de recibos de haberes y registración en el libro de sueldos." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Liquidaciones finales." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Asesoramiento en el trámite de rúbrica de libros." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Inscripciones varias, altas y bajas de empleados, confección de certificaciones de servicios." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Convenios Colectivos de Trabajo de la actividad." />
                </ListItem>
            </List>

            <h2>Asesoría Legal</h2>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Contestación y redacción de Cartas Documentos. " />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Atención de conflictos individuales y colectivos. " />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Redacción de Contratos laborales." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Seguimiento y resolución de reclamos en sede administrativa. " />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Representación, seguimiento y defensas en reclamos judiciales." />
                </ListItem>
            </List>
        </Grid>
    );
};

const Selection = () => {
    return (
        <Grid>
            <h2>Selección de Personal IT y Generalista</h2>
            <p>
                Realizamos entrevistas por competencias de acuerdo a la necesidad de cada
                cliente. Abarcamos empresas privadas, públicas o mixtas. Nacionales e
                internacionales. Mandos medios, gerenciales y operativos
            </p>
            <p>Nuestro trabajo se divide en distintas etapas:</p>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'}/>
                    </ListItemIcon>
                    <ListItemText primary="Publicación del aviso" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Hunting con selectoras especializadas" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Terna de Candidatos" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Informe individualizado de cada postulante" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Verificación de antecedentes" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Check fontSize="small" color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary="Gestión de preocupacional" />
                </ListItem>
            </List>
        </Grid>
    );
};
