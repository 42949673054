import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { SocialMedia, Copyright } from '../components';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        marginBottom: '30px',
    },
    titleInnerContainer: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        color: theme.palette.disabled,
        fontSize: '18px',
    },
    subtitle: {
        color: theme.palette.disabled,
        fontSize: '12px',
        marginBottom: '20px'
    },
    iconsContainer: {
        display: 'flex',
        alignSelf: 'stretch',
        justifyContent: 'space-around',
        marginTop: '20px',
    },
}));
export const Footer = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.footerContainer}>
            <Grid container item xs={12}>
                <Grid item xs={12} className={classes.titleContainer}>
                    <Grid item xs={12} md={2} className={classes.titleInnerContainer}>
                        <span className={classes.text}>Mayo Consultores</span>
                        <span align="center" className={classes.subtitle}>
                            Búsqueda de Talentos Humanos
                        </span>
                        <SocialMedia iconColor={'darkGrey'} />
                    </Grid>
                </Grid>
                <Copyright />
            </Grid>
        </Grid>
    );
};
