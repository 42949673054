import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    TextField,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { sendEmail } from '../api/sendEmail';
import { AppContext } from '../context/AppContext';
import { REGEX_EMAIL } from '../helpers/constants';
import { COLORS } from '../theme';
import { useMensajeUsuario } from './MensajeUsuario';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        '@media (max-width:820px)': {
            margin: theme.spacing(1),
        },
    },
    form: {
        '& .MuiFormControl-root': {
            marginBottom: theme.spacing(2),
        },
    },
}));

const defaultValues = {
    email: '',
    nombre: '',
    apellido: '',
    mensaje: '',
};

export const ContactForm = () => {
    const classes = useStyles();
    const { isMobile } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const { open, Mensaje } = useMensajeUsuario();
    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues,
        mode: 'onBlur',
    });

    const onSubmit = (data) => {
        setLoading(true);
        sendEmail(process.env.REACT_APP_EMAILJS_TEMPLATE_ID, reset, data)
            .then((resp) => {
                setLoading(false);
                open('El mensaje se ha enviado con éxito');
            })
            .catch((e) => {
                setLoading(false);
                open('Ha ocurrido un error al enviar el mensaje');
            });
    };

    return (
        <Grid container item xs={12} sm={7} id="contactForm">
            <Paper className={classes.pageContent}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid container spacing={isMobile ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name="nombre"
                                    render={({ onChange, onBlur, value }) => {
                                        return (
                                            <TextField
                                                label="Nombre *"
                                                variant="outlined"
                                                fullWidth
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!errors.nombre}
                                                helperText={errors.nombre?.message}
                                            />
                                        );
                                    }}
                                    rules={{
                                        required: 'El campo es requerido',
                                        minLength: {
                                            value: 3,
                                            message:
                                                'El nombre debe tener al menos 3 letras',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name="apellido"
                                    render={({ onChange, onBlur, value }) => {
                                        return (
                                            <TextField
                                                label="Apellido *"
                                                variant="outlined"
                                                fullWidth
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!errors.apellido}
                                                helperText={errors.apellido?.message}
                                            />
                                        );
                                    }}
                                    rules={{
                                        required: 'El campo es requerido',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="email"
                                render={({ onChange, onBlur, value }) => {
                                    return (
                                        <TextField
                                            label="Email *"
                                            variant="outlined"
                                            fullWidth
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                            type="email"
                                        />
                                    );
                                }}
                                rules={{
                                    required: 'El campo es requerido',
                                    pattern: {
                                        value: REGEX_EMAIL,
                                        message: 'Email inválido',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="mensaje"
                                render={({ onChange, onBlur, value }) => {
                                    return (
                                        <TextField
                                            label="Mensaje *"
                                            variant="outlined"
                                            fullWidth
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            error={!!errors.mensaje}
                                            helperText={errors.mensaje?.message}
                                        />
                                    );
                                }}
                                rules={{
                                    required: 'El campo es requerido',
                                    minLength: {
                                        value: 10,
                                        message: 'Mensaje muy corto',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </form>
                <Button
                    disabled={loading}
                    variant="contained"
                    style={{
                        backgroundColor: COLORS.THREE,
                        color: COLORS.CONTRAST,
                    }}
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                >
                    {loading ? <CircularProgress /> : 'Enviar'}
                </Button>
                <Mensaje />
            </Paper>
        </Grid>
    );
};
