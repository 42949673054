import { Grid, makeStyles } from '@material-ui/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useContext, useEffect, useRef } from 'react';

import { CrossesBackground, OverlayColorBackground } from '../components';
import { AppContext } from '../context/AppContext';
import { TeamItem } from './TeamItem';

import { COLORS } from '../theme';

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
    teamContainer: {
        position: 'relative',
        // marginTop: '25px',
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        // marginTop: '25px',
        // marginBottom: '30px',
    },
    title: {
        '@media (max-width:820px)': {
            margin: '0px',
        },
        ...theme.typography.sectionTitle,
    },
    subtitle: {
        ...theme.typography.sectionSubtitle,
        marginRight: '50px',
        marginLeft: '50px',
        textAlign: 'center',
    },
    imageContainer: {
        marginTop: '60px',
        marginBottom: '50px',
    },
}));

export const Team = () => {
    const classes = useStyles();
    const {
        state: { config },
        isMobile,
    } = useContext(AppContext);

    const height = isMobile ? '85%' : '40%';
    const teamRef = useRef();
    useEffect(() => {
        const el = teamRef.current;
        gsap.fromTo(
            el,
            { opacity: 0, transform: 'translate3d(0, 20%, 0)' },
            {
                opacity: 1,
                duration: '1',
                transform: 'none',
                scrollTrigger: {
                    trigger: el,
                },
            }
        );
    }, [config]);

    if (config === null) return null;
    const { team } = config;
    return (
        <Grid container className={classes.teamContainer} ref={teamRef}>
            <Grid container item xs={12}>
                <Grid item xs={12} className={classes.titleContainer}>
                    <h1 className={classes.title}>{team.tituloSeccion}</h1>
                    <span className={classes.subtitle}>{team.descripcionSeccion}</span>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-around"
                    className={classes.imageContainer}
                >
                    {team.miembros.map((m, i) => (
                        <TeamItem
                            key={m.nombre + i}
                            imageSrc={m.url}
                            nombre={m.nombre}
                            puesto={m.posicion}
                        />
                    ))}
                </Grid>
            </Grid>
            <CrossesBackground width={'50%'} />
            <OverlayColorBackground height={height} backgroundColor={COLORS.FOUR} />
        </Grid>
    );
};
