import { Route, Routes } from 'react-router';

import { Home } from '../pages/home/Home';
import { Contacto } from '../pages/contacto/Contacto';

import {
    AdminServicios,
    Administracion,
    AdminContacto,
    AdminTeam,
    AdminMision,
} from '../pages/administracion';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { PrivateNavbar } from '../components/PrivateNavbar';
import { Servicios } from '../pages/servicios/Servicios';

export const MainRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<PublicRoute component={Home} />} />
            <Route path="/servicios" element={<PublicRoute component={Servicios} />} />
            <Route path="/contacto" element={<PublicRoute component={Contacto} />} />
            <Route
                path="/administracion/*"
                element={
                    <PrivateRoute>
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={<PrivateNavbar component={Administracion} />}
                            />
                            <Route
                                path="/servicios"
                                element={<PrivateNavbar component={AdminServicios} />}
                            />
                            <Route
                                path="/mision"
                                element={<PrivateNavbar component={AdminMision} />}
                            />
                            <Route
                                path="/contacto"
                                element={<PrivateNavbar component={AdminContacto} />}
                            />
                            <Route
                                path="/equipo"
                                element={<PrivateNavbar component={AdminTeam} />}
                            />
                        </Routes>
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};
