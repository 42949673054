import { Snackbar } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

export const useMensajeUsuario = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState();
    const close = () => {
        setShow(false);
    };

    const open = useCallback((message) => {
        setShow(true);
        setMessage(message);
    }, []);

    const handleClose = useCallback(() => {
        setShow(false);
    }, []);

    const Mensaje = () => {
        return (
            <Snackbar
                open={show}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
            />
        );
    };
    return {
        close,
        open,
        Mensaje,
    };
};
