import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { COLORS } from '../theme';

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        textAlign: 'center',
        borderBottom: `4px solid ${COLORS.THREE}`,
        '@media (max-width:820px)': {
            marginBottom: '30px',
        },
    },
    card: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        padding: '40px 60px',
        ...theme.paper,
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        // marginTop: '10px',
    },
    title: {
        fontSize: '2vw',
        fontWeight: 'bold',
        color: theme.palette.three.main,
        '@media (max-width:820px)': {
            fontSize: '1.5rem',
        },
    },
    subtitle: {
        fontSize: '1.3vw',
        fontWeight: '300',
        color: theme.palette.darkGrey.main,
        marginTop: '5px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '3rem',
        '@media (max-width:820px)': {
            fontSize: '1rem',
        },
    },
    firstCircle: {
        position: 'absolute',
        borderRadius: '100%',
        backgroundColor: 'white',
        width: '20%',
        height: '28%',
        bottom: '-10%',
        left: '40%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    innerCircle: {
        backgroundColor: COLORS.THREE,
        width: '85%',
        height: '85%',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const MisionItem = ({ title, description, image, icon }) => {
    const classes = useStyles();
    const picture = require(`../theme/img/${image}`);
    return (
        <Grid item className={classes.itemContainer} xs={10} sm={3}>
            <div style={{ position: 'relative', marginBottom: '15%' }}>
                <img src={picture} alt="title" style={{ width: '100%' }} />
                <div className={classes.firstCircle}>
                    <div className={classes.innerCircle}>{icon}</div>
                </div>
            </div>

            <Grid className={classes.descriptionContainer}>
                <span className={classes.title}>{title}</span>
                <span className={classes.subtitle}>{description}</span>
            </Grid>
        </Grid>
    );
};
