import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { ExpandMoreRounded } from '@material-ui/icons';
import { COLORS } from '../theme';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useEffect } from 'react';

export const AccordionItem = ({ itemIndex, item, campos, tituloItem, onEdit }) => {
    const { control, handleSubmit, errors } = useForm({
        defaultValues: item,
    });
    const [loading, setLoading] = useState(true);

    const handleEdit = (data) => {
        setLoading(true);
        onEdit(data, itemIndex);
    };
    useEffect(() => {
        setLoading(false);
    }, [item]);

    return (
        <Accordion key={tituloItem}>
            <AccordionSummary
                expandIcon={<ExpandMoreRounded />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{tituloItem}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={handleSubmit(handleEdit)} style={{ width: '100%' }}>
                    <Grid container item direction="column" spacing={2}>
                        {campos.map((campo) => (
                            <Grid item key={campo}>
                                <Controller
                                    control={control}
                                    name={campo}
                                    render={({ onChange, onBlur, value }) => {
                                        return (
                                            <TextField
                                                label={campo}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!errors[campo]}
                                                helperText={errors[campo]?.message}
                                                value={value}
                                            />
                                        );
                                    }}
                                    rules={{
                                        required: 'El campo es requerido',
                                    }}
                                    style={{ marginBottom: '10px' }}
                                />
                            </Grid>
                        ))}
                        <Grid container item alignItems="center">
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: COLORS.THREE,
                                    color: COLORS.CONTRAST,
                                }}
                                type="submit"
                                disabled={loading}
                            >
                                Editar
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={20}
                                    style={{ marginLeft: '10px' }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    );
};
