import React from 'react';
import { makeStyles } from '@material-ui/core';

import cruces from '../theme/img/cruces.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 0,
    },
}));

export const CrossesBackground = ({ width }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img src={cruces} alt={'fondo'} style={{ width: `${width}` }}></img>
        </div>
    );
};
