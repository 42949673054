import { Typography } from '@material-ui/core';
import React from 'react';

export const Administracion = () => {
    return (
        <>
            <Typography variant="h3">Administración</Typography>
        </>
    );
};
