import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        bottom: 0,
        height: '280px',
        width: '100%',
        zIndex: '-1',
    },
}));

export const OverlayColorBackground = ({ height, backgroundColor }) => {
    const classes = useStyles();
    return (
        <div
            style={{
                height: `${height}`,
                backgroundColor: `${backgroundColor}`,
            }}
            className={classes.container}
        ></div>
    );
};
