// import React, { useReducer, useCallback, createContext, useMemo, useEffect } from 'react';
// import { types } from 'types/types';
// import { storage } from '../helpers/storage';
// import { signIn } from '../firebase/providers';

import { useEffect } from 'react';
import { useReducer } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { createContext } from 'react';
import { signIn, signOut } from '../firebase/providers';
import { storage } from '../helpers/storage';
export const UserContext = createContext();

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGED_ERROR = 'LOGGED_ERROR';
export const LOADING = 'LOADING';

const KEY_TOKEN = 'bth-token';

export const types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_STATE: 'SET_STATE',
    LOGIN_ERROR: 'LOGIN_ERROR',
};

const initialState = {
    state: LOADING,
    error: null,
    info: null,
};

export const reducer = (state, action) => {
    let errorMessage = '';

    switch (action.type) {
        case types.START_LOGIN:
            return initialState;

        case types.LOGIN:
            storage.saveInSessionStorage(KEY_TOKEN, 'bth-token');
            return {
                ...state,
                state: LOGGED_IN,
                error: null,
            };

        case types.LOGIN_ERROR:
            errorMessage =
                action.payload.code === 'auth/wrong-password' ||
                action.payload.code === 'auth/user-not-found'
                    ? 'login.incorrectNameOrPassword'
                    : 'login.anErrorHasOccurred';

            return {
                ...state,
                state: LOGGED_ERROR,
                error: errorMessage,
            };

        case types.LOGOUT:
            storage.removeFromSessionStorage(KEY_TOKEN);
            return {
                ...state,
                state: LOGGED_OUT,
                info: null,
                error: null,
            };

        case types.SET_STATE:
            return {
                ...state,
                state: action.payload.state,
                info: action.payload.info,
            };

        default:
            return state;
    }
};

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        const isLogged = Boolean(storage.getFromSessionStorage(KEY_TOKEN));
        const newState = {
            state: isLogged ? LOGGED_IN : LOGGED_OUT,
        };
        dispatch({
            type: types.SET_STATE,
            payload: newState,
        });
    }, []);
    const login = useCallback(
        (email, password) => {
            dispatch({ type: types.START_LOGIN });
            return signIn(email, password)
                .then((res) => {
                    dispatch({ type: types.LOGIN });
                })
                .catch((err) => {
                    console.log('error en login context');
                    dispatch({ type: types.LOGIN_ERROR, payload: err });
                    throw err;
                });
        },
        [dispatch]
    );
    const logout = useCallback(() => {
        signOut()
            .then(() => dispatch({ type: types.LOGOUT }))
            .catch(console.log);
    }, [dispatch]);
    const contextValue = useMemo(
        () => ({
            login,
            logout,
            userState: state,
        }),
        [login, logout, state]
    );

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
