import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './config';

export const signIn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
};

export const signOut = async () => {
    try {
        return await auth.signOut();
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error', `Code: ${errorCode}, Message: ${errorMessage}`);
    }
};
