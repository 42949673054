import CryptoJS from 'crypto-js'

const encode = (value) => {
    const wordArray = CryptoJS.enc.Utf8.parse(value);
    return CryptoJS.enc.Base64.stringify(wordArray);
};

const decode = (value) => {
    const parsedWordArray = CryptoJS.enc.Base64.parse(value);
    return parsedWordArray.toString(CryptoJS.enc.Utf8);
};

const saveInLocalStorage = (key, item) => {
    if (!isLocalStorageEnabled()) return null;
    return window.localStorage.setItem(encode(key), encode(item));
};

const getFromLocalStorage = (key, defaultValue) => {
    if (!isLocalStorageEnabled()) return null;
    if (!window.localStorage.getItem(encode(key))) {
        return defaultValue;
    }
    return decode(window.localStorage.getItem(encode(key)));
};

const removeFromLocalStorage = (key) => {
    if (!isSessionStorageEnabled()) return null;
    return window.sessionStorage.removeItem(encode(key));
};

const saveInSessionStorage = (key, item) => {
    if (!isSessionStorageEnabled()) return null;
    return window.sessionStorage.setItem(encode(key), encode(item));
};

const removeFromSessionStorage = (key) => {
    window.sessionStorage.removeItem(encode(key));
};

const getFromSessionStorage = (key, defaultValue) => {
    if (!isSessionStorageEnabled()) return null;
    if (!window.sessionStorage.getItem(encode(key))) {
        return defaultValue;
    }
    return decode(window.sessionStorage.getItem(encode(key)));
};

const isLocalStorageEnabled = () => {
    try {
        window.localStorage.getItem('dummyItem');
        return true;
    } catch (ex) {
        return false;
    }
};

const isSessionStorageEnabled = () => {
    try {
        window.sessionStorage.getItem('dummyItem');
        return true;
    } catch {
        return false;
    }
};

const storage = {
    saveInLocalStorage,
    getFromLocalStorage,
    saveInSessionStorage,
    getFromSessionStorage,
    removeFromLocalStorage,
    removeFromSessionStorage,
};

export { storage };
