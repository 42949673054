import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    flipCardContainer: {
        height: '600px',
        perspective: '1000px',
        '&:hover': {
            '& $flipCard': {
                transform: 'rotateY(180deg)',
            },
        },
        '@media (max-width:820px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
            marginBottom: '40px',
        },
        '@media (max-width:1280px)': {
            height: '350px',
        },
        paddingRight: '5px',
        paddingLeft: '5px',
    },
    flipCard: {
        position: 'relative',
        width: '100%',
        height: '100%',
        transition: 'transform 1s',
        transformStyle: 'preserve-3d',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    flipCardFront: {
        position: 'absolute',
        width: '85%',
        height: '100%',
        '-webkit-backface-visibility': 'hidden' /* Safari */,
        backfaceVisibility: 'hidden',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: '#333',
        color: '#fff',
        backgroundSize: 'cover',
    },
    flipCardBack: {
        position: 'absolute',
        width: '85%',
        height: '100%',
        backfaceVisibility: 'hidden' /* Safari */,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.two.main,
        // backgroundColor: '#4ECDC4',
        color: '#fff',
        transform: 'rotateY(180deg)',
    },
    textContainer: {
        height: '100%',
        // background: 'linear-gradient(#1b3b6f94, #1b3b6f0d)',
        background: 'linear-gradient(rgb(0 0 0 / 49%) 8%, #87878726)',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginTop: '20px',
        color: 'rgba(255,255,255,0.75)',
        fontSize: '1.5vw',
        fontWeight: 'bold',
        paddingLeft: '20px',
        paddingRight: '20px',
        '@media (max-width:820px)': {
            fontSize: '1.2rem',
        },
    },
    subtitle: {
        fontSize: '1vw',
        color: 'rgba(255,255,255,0.75)',
        marginTop: '15px',
        paddingLeft: '30px',
        paddingRight: '30px',
        '@media (max-width:820px)': {
            fontSize: '0.8rem',
        },
    },
    masInfoContainer: {
        margin: '30px 0px',
        '@media (max-width:1280px)': {
            margin: '10px 0px',
        },
    },
    masInfo: {
        textDecoration: 'none',
        borderRadius: '30px',
        padding: '10px 30px',
        color: 'white',
        border: '1px solid #FFFFFF',
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        fontSize: '1.2rem',
        '@media (max-width:820px)': {
            fontSize: '1rem',
            padding: '10px 30px',
            margin: 'auto',
        },
        '@media (max-width:1280px)': {
            fontSize: '1rem',
        },
        gap: '10px'
    },
}));

export const ServiceItem = ({ item }) => {
    const path = item.titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ', '') 
    const classes = useStyles();
    return (
        <Grid item xs={10} md={3} className={classes.flipCardContainer}>
            <Grid className={classes.flipCard}>
                <div
                    className={classes.flipCardFront}
                    style={{
                        backgroundImage: `url(${require(`../theme/img/${item.url}`)})`
                    }}
                >
                    <div className={classes.textContainer}>
                        <span className={classes.title}>{item.titulo}</span>
                    </div>
                </div>
                <div className={classes.flipCardBack}>
                    <Grid container style={{flexGrow: '1'}}>
                    <span className={classes.title} style={{ textAlign: 'center' }}>
                        {item.titulo}
                    </span>
                    <span className={classes.subtitle}>{item.parrafo1}</span>
                    <span className={classes.subtitle}>{item.parrafo2}</span>
                    <span className={classes.subtitle}>{item.parrafo3}</span>
                    </Grid>
                    <Grid container justifyContent='center' style={{margin: '30px 0px'}} className={classes.masInfoContainer}>
                        <Link
                            to={`/servicios#${path}`}
                            className={classes.masInfo}
                            style={{ cursor: 'pointer' }}
                            
                        >
                            <BsArrowRight className={classes.flecha} />
                            <span>Más información</span>
                        </Link>
                    </Grid>
                    {/* <Grid item container direction="column">
                        <span className={classes.subtitle}>
                            <BsDot />
                            Headhunting
                        </span>
                        <span className={classes.subtitle}>
                            <BsDot />
                            Publicación en bolsas de trabajo
                        </span>
                        <span className={classes.subtitle}>
                            <BsDot />
                            Entrega de informe de candidatos
                        </span>
                    </Grid> */}
                </div>
            </Grid>
        </Grid>
    );
};
