import React, { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExitToApp as LogoutIcon } from '@material-ui/icons';
import { UserContext } from '../context/UserContext';
import { BsGem } from 'react-icons/bs';
import {
    MdOutlineHome,
    MdOutlineMailOutline,
    MdPeopleOutline,
    MdWorkOutline,
} from 'react-icons/md';
import { goToSection } from '../helpers/functions';
import { AppContext } from '../context/AppContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
}));

export const PrivateNavbar = ({ component: Component }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { logout } = useContext(UserContext);
    const {
        state: { config },
    } = useContext(AppContext);
    const [open, setOpen] = React.useState(false);

    if (config === null) return null;
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        BTH Administración
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <HashLink
                        smooth
                        to={'/administracion'}
                        className={classes.link}
                        style={{ cursor: 'pointer' }}
                    >
                        <ListItem button key={'home'}>
                            <ListItemIcon>
                                <MdOutlineHome className="MuiSvgIcon-root" />
                            </ListItemIcon>

                            <ListItemText primary={'Home'} />
                        </ListItem>
                    </HashLink>
                    <HashLink
                        to={'/administracion/servicios'}
                        className={classes.link}
                        style={{ cursor: 'pointer' }}
                        scroll={(el) => goToSection(el)}
                    >
                        <ListItem button key={'servicios'}>
                            <ListItemIcon>
                                <MdWorkOutline className="MuiSvgIcon-root" />
                            </ListItemIcon>

                            <ListItemText primary={config.servicio.tituloSeccion} />
                        </ListItem>
                    </HashLink>
                    <HashLink
                        to={'/administracion/mision'}
                        className={classes.link}
                        style={{ cursor: 'pointer' }}
                        scroll={(el) => goToSection(el)}
                    >
                        <ListItem button key={'mision'}>
                            <ListItemIcon>
                                <BsGem className="MuiSvgIcon-root" />
                            </ListItemIcon>

                            <ListItemText primary={config.mision.tituloSeccion} />
                        </ListItem>
                    </HashLink>
                    <HashLink
                        to={'/administracion/equipo'}
                        className={classes.link}
                        style={{ cursor: 'pointer' }}
                        scroll={(el) => goToSection(el)}
                    >
                        <ListItem button key={'equipo'}>
                            <ListItemIcon>
                                <MdPeopleOutline className="MuiSvgIcon-root" />
                            </ListItemIcon>
                            <ListItemText primary={config.team.tituloSeccion} />
                        </ListItem>
                    </HashLink>
                    <HashLink
                        to={'/administracion/contacto'}
                        className={classes.link}
                        style={{ cursor: 'pointer' }}
                        scroll={(el) => goToSection(el)}
                    >
                        <ListItem button key={'contacto'}>
                            <ListItemIcon>
                                <MdOutlineMailOutline className="MuiSvgIcon-root" />
                            </ListItemIcon>
                            <ListItemText primary={'Datos Contacto'} />
                        </ListItem>
                    </HashLink>
                </List>
                <Divider />
                <List>
                    <ListItem button key={'logout'} onClick={logout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Cerrar Sesión'} />
                    </ListItem>
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <Component />
            </main>
        </div>
    );
};
