import { makeStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedinIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';

const useStyles = makeStyles((theme) => ({
    iconsContainer: {
        display: 'flex',
        alignSelf: 'stretch',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    icon: {
        marginRight: '5px',
        marginLeft: '5px',
    },
    iconLink: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export const SocialMedia = ({ iconColor }) => {
    const classes = useStyles();
    const {
        state: { config },
    } = useContext(AppContext);

    if (config === null) return null;
    const { whatsapp, facebook, linkedin, instagram, email } = config;
    return (
        <div className={classes.iconsContainer}>
            <a target={'_blank'} rel={'noreferrer'} href={facebook} className={classes.iconLink}>
                <FacebookIcon
                    fontSize="medium"
                    htmlColor={`${iconColor}`}
                    className={classes.icon}
                />
            </a>
            <a target={'_blank'} rel={'noreferrer'} href={linkedin} className={classes.iconLink}>
                <LinkedinIcon
                    fontSize="medium"
                    htmlColor={`${iconColor}`}
                    className={classes.icon}
                />
            </a>
            <a target={'_blank'} rel={'noreferrer'} href={instagram} className={classes.iconLink}>
                <InstagramIcon
                    fontSize="medium"
                    htmlColor={`${iconColor}`}
                    className={classes.icon}
                />
            </a>
            <a
                target={'_blank'}
                rel={'noreferrer'}
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`}
                className={classes.iconLink}
            >
                <EmailIcon
                    fontSize="medium"
                    htmlColor={`${iconColor}`}
                    className={classes.icon}
                />
            </a>
            <a
                href={`https://wa.me/+54${whatsapp.numero}?text=${whatsapp.mensaje}`}
                target={'_blank'}
                rel={'noreferrer'}
                className={classes.iconLink}
            >
                <WhatsAppIcon
                    fontSize="medium"
                    htmlColor={`${iconColor}`}
                    className={classes.icon}
                />
            </a>
        </div>
    );
};
