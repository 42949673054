import React, { useContext, useState } from 'react';
import {
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    makeStyles,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { AppContext, types } from '../../context/AppContext';
import {
    editContactSeccion,
} from '../../api/config';
import { useMensajeUsuario } from '../../components/MensajeUsuario';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { COLORS } from '../../theme';
import { REGEX_EMAIL, REGEX_HTTP } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        '@media (max-width:820px)': {
            margin: theme.spacing(1),
        },
    },
}));

const defaultValues = {
    email: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    mensajeWhatsapp: '',
    numeroWhatsapp: '',
};

export const AdminContacto = () => {
    const classes = useStyles();
    const {
        state: { config },
        editSection,
    } = useContext(AppContext);
    const { open, Mensaje } = useMensajeUsuario();
    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues,
        mode: 'onBlur',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reset({
            email: config.email,
            facebook: config.facebook,
            instagram: config.instagram,
            linkedin: config.linkedin,
            mensajeWhatsapp: config.whatsapp.mensaje,
            numeroWhatsapp: config.whatsapp.numero,
        });
    }, [
        config.email,
        config.facebook,
        config.instagram,
        config.mision,
        config.linkedin,
        config.whatsapp.mensaje,
        config.whatsapp.numero,
        reset,
    ]);

    if (config === null) return null;

    const handleEditSeccion = (data) => {
        setLoading(true);
        editContactSeccion(data)
            .then(() => {
                editSection(types.CONTACT_SECTION_EDIT, data);
                setLoading(false);
                open('La edición se realizó de forma correcta');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                open('Ha ocurrido un error al actualizar');
            });
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Typography variant="h4">Datos de Contacto</Typography>
            </Grid>
            <Grid item>
                <Paper className={classes.pageContent}>
                    <Grid container direction="column" spacing={4}>
                        <form
                            onSubmit={handleSubmit(handleEditSeccion)}
                            style={{ margin: '15px' }}
                        >
                            <Grid container item direction="column" spacing={2}>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'email'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Email'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.email}
                                                    helperText={errors.email?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                            pattern: {
                                                value: REGEX_EMAIL,
                                                message: 'Email inválido',
                                            },
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'facebook'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Facebook'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.facebook}
                                                    helperText={errors.facebook?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                            pattern: {
                                                value: REGEX_HTTP,
                                                message: 'Url inválida',
                                            },
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'instagram'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Instagram'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.instagram}
                                                    helperText={errors.instagram?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                            pattern: {
                                                value: REGEX_HTTP,
                                                message: 'Url inválida',
                                            },
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'linkedin'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'linkedin'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.linkedin}
                                                    helperText={errors.linkedin?.message}
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                            pattern: {
                                                value: REGEX_HTTP,
                                                message: 'Url inválida',
                                            },
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'mensajeWhatsapp'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Mensaje de Whatsapp'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.mensajeWhatsapp}
                                                    helperText={
                                                        errors.mensajeWhatsapp?.message
                                                    }
                                                    value={value}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controller
                                        control={control}
                                        name={'numeroWhatsapp'}
                                        render={({ onChange, onBlur, value }) => {
                                            return (
                                                <TextField
                                                    label={'Número de Whatsapp'}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!errors.numeroWhatsapp}
                                                    helperText={
                                                        errors.numeroWhatsapp?.message
                                                    }
                                                    value={value}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                (+54)
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            );
                                        }}
                                        rules={{
                                            required: 'El campo es requerido',
                                        }}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid container item alignItems="center">
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: COLORS.THREE,
                                            color: COLORS.CONTRAST,
                                        }}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Editar
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={20}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Paper>
                <Mensaje />
            </Grid>
        </Grid>
    );
};
