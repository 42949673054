import { collection, getDocs, updateDoc, doc } from 'firebase/firestore/lite';
import { config } from '../config';
import { db } from '../firebase/config';

export const getConfig = async () => {
    try {
        // const configRef = await collection(db, 'config');
        // const headerSnap = await getDocs(configRef);
        return config
    } catch (error) {
        console.log(error);
    }
};

export const editServices = async (arrayServices) => {
    try {
        const { docRef } = await getDocRef();
        const { item: servicio } = await getItemConfig('servicio');
        updateDoc(docRef, {
            servicio: {
                ...servicio,
                servicios: arrayServices,
            },
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const editTeams = async (arrayMiembros) => {
    try {
        const { docRef } = await getDocRef();
        const { item: team } = await getItemConfig('team');
        updateDoc(docRef, {
            team: {
                ...team,
                miembros: arrayMiembros,
            },
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const editServiceSeccion = async (data) => {
    try {
        const { docRef } = await getDocRef();
        const { item: servicio } = await getItemConfig('servicio');
        updateDoc(docRef, {
            servicio: {
                ...servicio,
                ...data,
            },
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const editTeamSeccion = async (data) => {
    try {
        const { docRef } = await getDocRef();
        const { item: team } = await getItemConfig('team');
        updateDoc(docRef, {
            team: {
                ...team,
                ...data,
            },
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const editMisionSeccion = async (data) => {
    try {
        const { docRef } = await getDocRef();
        const { item: mision } = await getItemConfig('mision');
        updateDoc(docRef, {
            mision: {
                ...mision,
                ...data,
            },
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const editContactSeccion = async (data) => {
    try {
        const { docRef } = await getDocRef();
        const { item: whatsapp } = await getItemConfig('whatsapp');

        updateDoc(docRef, {
            email: data.email,
            facebook: data.facebook,
            linkedin: data.linkedin,
            instagram: data.instagram,
            whatsapp: {
                ...whatsapp,
                mensaje: data.mensajeWhatsapp,
                numero: data.numeroWhatsapp,
            },
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getItemConfig = async (itemConfig) => {
    const configRef = await collection(db, 'config');
    const configSnap = await getDocs(configRef);
    const item = configSnap.docs[0].data()[itemConfig];
    return {
        item,
    };
};

const getDocRef = async () => {
    const configRef = await collection(db, 'config');
    const collectionPath = configRef.path;
    const configSnap = await getDocs(configRef);
    const idDocument = configSnap.docs[0].id;
    const docRef = doc(db, `/${collectionPath}/${idDocument}`);
    return {
        docRef,
    };
};
